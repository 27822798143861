import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { Eva } from '../data/eva';
import { exclude } from '../utils/arrays';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  evas: Eva[] = [];
  page = 1;
  total_pages = 1;

  get sortedEvas() {
    return this.evas.sort((a, b) => (a.created > b.created ? -1 : 1));
  }

  constructor(private http: HttpClient, public appService: AppService) {
    this.fetchPage();
  }

  fetchPage() {
    this.http
      .get('api/evas?page=' + this.page)
      .subscribe((data: any) => {
        this.evas = data.evas.map((e: any) => Eva.fromDict(e));
        this.total_pages = data.total_pages;
      });
  }

  startNewEva() {
    this.http
      .post('api/evas', {})
      .subscribe((data: any) => (window.location.href = data.eva_id));
  }

  delete(eva: Eva) {
    this.http
      .delete('api/evas/' + eva.id)
      .subscribe(
        () => exclude(this.evas, eva),
          (error) => console.log(error)
      );
  }

  hasPrev() {
    return this.page > 1;
  }

  hasNext() {
    return this.page < this.total_pages;
  }

  load_next() {
    this.page += 1
    this.fetchPage()
  }

  load_prev() {
    this.page -= 1
    this.fetchPage()
  }
}
